<template>
    <div >
       <div class="curContainer">
            <div  width="100%">
              
              <el-row type="flex" justify="end" style="margin-top:5px;">
                    <!-- <el-button type="primary" size="small" icon="el-icon-download" @click.stop="onExportClick()">添加</el-button> -->
                <el-button type="primary" @click="onItemNew()" style="margin-right:10px;">新建</el-button>
                <el-button type="primary" @click="onSync()" style="margin-right:10px;">同步</el-button>
                
              </el-row>
              <el-form inline :model="productSearchForm" label-width="140px" style="margin-top: 30px;">
                <el-form-item label="选择站点">
                  <el-cascader
                      :options="areaStationList"
                      :show-all-levels="false"    
                      :props="props"
                      v-model="productSearchForm.stationArray"
                      collapse-tags
                      @change="onStationXmlChange(stationArray=$event)"
                      clearable></el-cascader>
                </el-form-item>
                <el-form-item label="状态">
                  <el-select v-model="productSearchForm.statusName" clearable placeholder="请选择">
                    <el-option
                      v-for="item in statusName"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="关键字">
                  <el-input v-model="productSearchForm.key" autocomplete="off"></el-input>
                </el-form-item>
                <el-button @click="onProductSearch" type="primary">搜索</el-button>
              </el-form>

              <div v-if="selectedMediaList.length>0" style="display:flex;justify-content:flex-end;margin-right:5px;margin-bottom: 5px;">
                <!-- <el-button type="primary"  @click="viewSetDateVisible=true" >设置到期日期</el-button> -->
                <el-button type="primary"  @click="onSetRemark" >设置说明</el-button>
                <el-button type="primary" @click="viewSetStatusVisible=true">设置状态</el-button>
              </div>
              <!-- <common-table :data="moniDataList"> -->
              <common-table ref="tableObj"  :data="Media_LedList" border 
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                @selection-change="handleSelectionChange"
                style="width: 100%;margin-top: 10px;" :row-key="getRowKey" stripe>
                <el-table-column type="selection" reserve-selection width="55"></el-table-column>
                <el-table-column prop="ydarea" align="center" label="大区" ></el-table-column>
                <el-table-column prop="province" align="center" label="省" ></el-table-column>
                <el-table-column prop="city" align="center" label="市" ></el-table-column>
                <el-table-column prop="station" align="center" label="车站" >
                  <template v-slot="scope">
                      <el-button type="text" @click="openStation(scope.row.station)">{{scope.row.station}}</el-button>                                    
                  </template>
                </el-table-column>
                <el-table-column prop="position" align="center" label="位置" ></el-table-column>
              <!-- <el-table-column prop="sub1Name" align="center" label="媒体形式1" ></el-table-column>
              <el-table-column prop="sub2Name" align="center" label="媒体形式2" ></el-table-column> -->
              
              <el-table-column prop="address" align="center" label="媒体位置" ></el-table-column>
              
              <el-table-column prop="mediaCode" align="center" label="媒体编号" >
                <template v-slot="scope">
                      <el-button type="text" @click="onItemEditClick(scope.row)">{{scope.row.mediaCode}}</el-button>                                    
                      <!-- {{scope.row.mediaCode}} -->
                  </template>
              </el-table-column>
              <el-table-column prop="packageName" align="center" label="套餐" sortable ></el-table-column>
              <!-- <el-table-column prop="count" align="center" label="数量(块)" ></el-table-column> -->
              <el-table-column prop="adSize" align="center" label="媒体尺寸(m*m)" ></el-table-column>
              <el-table-column prop="adArea" align="center" label="媒体面积(㎡)" ></el-table-column>
              <!-- <el-table-column prop="sExpireDate" align="center" label="到期日期" sortable>
                <template v-slot="scope">
                      <span >{{scope.row.sExpireDate}}</span>  
                  </template>
              </el-table-column> -->
              <!-- <el-table-column align="center" label="状态" >
                <template v-slot="scope">
                      <span :class="scope.row.isDel?'error':'ok'">{{scope.row.isDel?'删除':'正常'}}</span>  
                  </template>
              </el-table-column> -->
              <el-table-column align="center" label="状态" >
                <template v-slot="scope">
                      <span :class="scope.row.statusName=='优化'?'youhua':scope.row.statusName=='新增'?'xinzeng':scope.row.statusName=='到期'?'daoqi':scope.row.statusName=='正常'?'zhengchang':'yichang'">{{scope.row.statusName}}</span>  
                  </template>
              </el-table-column>
              <el-table-column prop="remark" align="center" label="说明" ></el-table-column>
              </common-table>
             
              <el-dialog title="车站介绍" v-model="viewStationVisible" width="80%"  @open="open()"
                                              :modal-append-to-body='false'
                                              :close-on-click-modal='false'
                                              v-if="viewStationVisible"
                                              append-to-body>
                                      <div style="height:2800px;">
                                          <station @close="viewStationVisible=false" :station="station"/>
                                      </div>
              </el-dialog>

              <el-dialog title="站点图片" v-model="viewImageVisible" width="40%" @open="open()"
                                              :modal-append-to-body='false'
                                              :close-on-click-modal='false'
                                              append-to-body>
                              <div>
                                  <el-carousel :interval="4000" type="card" height="400px">
                                      <el-carousel-item v-for="item in imageList" :key="item">
                                      <!-- <h3 class="medium">{{ item }}</h3> -->
                                      <el-image :src="item" >
                                          <!-- :preview-src-list="imageList" -->
                                          <div class="image-slot">
                                              加载中<span class="dot">...</span>
                                          </div>
                                      </el-image>
                                      </el-carousel-item>
                                  </el-carousel>
                              </div>
                              <div class="button-footer">                      
                                  <span >
                                      <el-button @click="viewImageVisible = false">关闭</el-button>
                                  </span>                    
                              </div>
              </el-dialog>

              <el-dialog title="设置到期日期" v-model="viewSetDateVisible" width="40%" @open="open()"
                                              :modal-append-to-body='false'
                                              :close-on-click-modal='false'
                                              append-to-body>
                  <div>
                    <span class="demonstration">到期日期</span>
                    <el-date-picker
                      style="margin-left:15px;"
                      v-model="setForm.expDate"
                      align="right"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="pickerOptions">
                    </el-date-picker>         
                  </div>
                  <div class="button-footer">                      
                      <span >
                          <el-button type="primary" @click="onSaveDate()">确定</el-button>
                          <el-button @click="viewSetDateVisible = false">关闭</el-button>
                      </span>                    
                  </div>
              </el-dialog>      

              <el-dialog title="设置状态" v-model="viewSetStatusVisible" width="40%" @open="open()"
                                              :modal-append-to-body='false'
                                              :close-on-click-modal='false'
                                              append-to-body>
                  <div>
                    <!-- <el-form-item label="状态"> -->
                      状态
                         <el-select style="margin-left:5px;" v-model="setForm.statusName" placeholder="请选择">
                            <el-option
                              v-for="item in statusName"
                              :key="item.name"
                              :label="item.name"
                              :value="item.name">
                            </el-option>
                          </el-select>
                    <!-- </el-form-item>   -->
                  </div>
                  <div class="button-footer">                      
                      <span >
                          <el-button type="primary" @click="onSetStatus()">确定</el-button>
                          <el-button @click="viewSetStatusVisible = false">关闭</el-button>
                      </span>                    
                  </div>
              </el-dialog>      
              
              <el-dialog title="修改资源" v-model="editDialogVisible" width="60%" @open="open()"
                                              :modal-append-to-body='false'
                                              :close-on-click-modal='false'
                                              append-to-body>
                  <div>
                    <el-form  ref="form" :model="searchForm" label-width="138px" style="margin-top:20px;" label-position="right">
                    <el-row>                        
                        <el-form-item label="媒体编码"  >
                            <el-input  v-model="curItem.mediaCode" placeholder="请输入媒体编码">{{curItem.mediaCode}}</el-input>
                        </el-form-item>
                        <el-form-item v-if="curItem.mediaId==''" label="选择站点">
                          <el-cascader
                              :options="areaStationList"
                              :show-all-levels="false"    
                              :props="{multiple: false}"
                              v-model="curItem.stationId"
                              collapse-tags
                          ></el-cascader>
                        </el-form-item>
                        <el-form-item v-if="curItem.mediaId==''" label="类型"  >
                            <el-select v-model="curItem.typeId" clearable placeholder="请选择类型">
                            <el-option
                                v-for="item in tmpMediaTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="类型1"  style="margin-left:30px;">
                            <el-select v-model="curItem.sub1TypeId" clearable multiple placeholder="请选择类型">
                            <el-option
                                v-for="item in mediaTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="类型2"  style="margin-left:30px;">
                            <el-select v-model="curItem.sub1TypeId" clearable multiple placeholder="请选择类型">
                            <el-option
                                v-for="item in mediaTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                            </el-select>
                        </el-form-item> -->
<!-- 
                        <el-form-item label="位置"  style="margin-left:30px;">
                            <el-select v-model="curItem.positionId" clearable multiple placeholder="请选择位置">
                            <el-option
                                v-for="item in positionList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="位置"  >
                          <el-select
                            v-model="curItem.positionId"
                            placeholder="请选择位置"
                          >
                            <el-option
                              v-for="item in positionList"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>

                        <el-form-item label="具体位置" >
                            <el-input  v-model="curItem.address" placeholder="请输入具体位置">{{curItem.address}}</el-input>
                        </el-form-item>
                        <el-form-item label="广告面积占比"  >
                            <el-input  v-model="curItem.areaPercent" placeholder="请输入广告面积占比">{{curItem.areaPercent}}</el-input>
                        </el-form-item>
                        <el-form-item label="尺寸" >
                            <el-input  v-model="curItem.adSize" placeholder="请输入尺寸">{{curItem.adSize}}</el-input>
                        </el-form-item>
                        <el-form-item label="面积"  >
                            <el-input  v-model="curItem.adArea" placeholder="请输入面积">{{curItem.adArea}}</el-input>
                        </el-form-item>
                        <!-- <el-row> -->
                                                             
                        <!-- </el-row>
                        <el-row> -->
                        <el-form-item label="说明" >
                            <!-- <el-input  v-model="curItem.remark" placeholder="请输入说明">{{curItem.remark}}</el-input> -->
                            <el-input v-model="curItem.remark" type="textarea" autocomplete="off" :rows="3"
                                                                style="width: 500px;">{{curItem.remark}}</el-input>
                        </el-form-item>
                        
                      <!-- </el-row> -->
                      <div class="text-ele">
                          <my-upload-file-form-item label="媒体资源及位置图" :limit="2"  :subDir="'媒体资源照片'" :file-list="curItem.imgArr" v-model="curItem.imgArr"/>                                    
                      </div>    
                        <el-form-item label="到期日期">        
                          <el-date-picker
                            style="margin-left:0px;"
                            v-model="curItem.sExpireDate"                          
                            type="date"
                            placeholder="选择日期"
                            :picker-options="pickerOptions">
                          </el-date-picker> 
                        </el-form-item>    
                                
                    </el-row>
                    <el-row>
                      <el-form-item label="状态">        
                          <!-- <el-switch
                          v-model="curItem.isDel"
                          active-text="删除"
                          inactive-text="正常">
                        </el-switch> -->
                         <el-select v-model="curItem.statusName" placeholder="请选择">
                            <el-option
                              v-for="item in statusName"
                              :key="item.name"
                              :label="item.name"
                              :value="item.name">
                            </el-option>
                          </el-select>
                        </el-form-item>    
                    </el-row>
                </el-form>                         
                  </div>
                  <div class="button-footer">                      
                      <span >
                          <el-button type="primary" @click="onSaveModify()">确定</el-button>
                          <el-button @click="editDialogVisible = false">关闭</el-button>
                      </span>                    
                  </div>
              </el-dialog>   
            </div>
       </div>
    </div>
</template>

<script>
import CommonTable from "@/components/CommonTable";
// import {getLatestMonthDateArrayByDate} from "@/utils/time";
import { mapGetters } from "vuex";
import { getToken } from "@/utils/auth";
import {getCurrentDate} from "@/utils/time";
// import {dealDeleteOperation} from "@/utils/tools";
// import {delBrand} from "@/api/auditing";
// import EditBrandDialog from "@/views/Operate/Brand/Market/EditBrandDialog";
import Station from '@/views/Business/Station';
import {SetExpireDate,UpdateMedia,UpdateMedia_isDel,SetMediaRemark,SetMediaStatus,Sync_UpdatePackageAndProductStatus} from '@/api/operate';
import MyUploadFileFormItem from "@/components/MyUploadFileFormItem";
import {getFileUrl} from "@/api/file";

export default {
  name: "Advertiser",
  components: { CommonTable,Station,MyUploadFileFormItem}, 
  computed: { ...mapGetters(["areaStationList","Media_LedList","positionList",'mediaTypeList']) },
  data() {
    return {      
      props: { multiple: true },

      editDialogVisible: false,
      productSearchForm: {
        key: "",
        // dateArray: getLatestMonthDateArrayByDate({date:new Date(),delayNum:1, formatValue: 'yyyy-MM-dd'}),
        stationArray:[],
        stationStr:'',
        statusName:'',
      },
      curItem: {mediaId:'',imgArr:[]},

      viewStationVisible:false,
      viewImageVisible:false,
      imageList:[],
      tmpMediaTypeList:[],

      selectedMediaList:[],
      viewSetDateVisible:false,
      setForm:{
        expDate:getCurrentDate(),
        statusName:'正常',
      },
      pickerOptions: {
          // disabledDate(time) {
          //   return time.getTime() > Date.now();
          // },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '一个月后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 30);
              picker.$emit('pick', date);
            }
          }, {
            text: '三个月后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30*3);
              picker.$emit('pick', date);
            }
          }, {
            text: '半年后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30*6);
              picker.$emit('pick', date);
            }
          }, {
            text: '1年后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30*12);
              picker.$emit('pick', date);
            }
          }]
        },
      statusName:[{name:'正常'},{name:'新增'},{name:'到期'},{name:'优化'},{name:'异常'}],
      viewSetStatusVisible:false,
    };
  },
  async created() {
    // await this.$store.dispatch("auditing/jclxList", {});
    // await this.$store.dispatch("auditing/sjztList", {});
    await this.$store.dispatch('operate/areaStationList');
    await this.$store.dispatch('operate/positionList');
    await this.$store.dispatch('operate/mediaTypeList').then(()=>{
      this.tmpMediaTypeList=[];
      this.tmpMediaTypeList.push(this.mediaTypeList[0]);
    });
    await this.onProductSearch();
  },
  methods: {   
    async onSetRemark(){
      this.$prompt('请输入说明', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          // inputErrorMessage: '邮箱格式不正确'
        }).then(({ value }) => {
          // this.$message({
          //   type: 'success',
          //   message: '你的邮箱是: ' + value
          // });
          var mids="";
          this.selectedMediaList.forEach(ele => {
            mids=mids+','+ele.mediaId;
          });
          SetMediaRemark({userId: getToken(),mids,remark:value}).then(()=>{
            this.$message({message: '设置成功',type: 'success'});
            // this.selectedMediaList.forEach(ele => {
            //   this.Media_LedList.forEach(ele2 => {
            //     if (ele.mediaId==ele2.mediaId){
            //       ele2.sExpireDate = sDate;
            //     }
            //   });
            // });

            this.$refs.tableObj.$forceUpdate();
            this.SearchMediaList=[];
            this.$refs.tableObj.clearSelect();
            // this.viewSetDateVisible=false;
            this.onProductSearch();
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '取消输入'
          // });       
        });
      })
    },

    async onSaveDate(){
      var mids="";
      this.selectedMediaList.forEach(ele => {
        mids=mids+','+ele.mediaId;
      });
      var sDate = new Date(this.setForm.expDate).toLocaleDateString();
      await SetExpireDate({userId: getToken(),mids,date:sDate,type:1}).then(()=>{
        this.$message({message: '设置成功',type: 'success'});

        this.selectedMediaList.forEach(ele => {
          this.Media_LedList.forEach(ele2 => {
            if (ele.mediaId==ele2.mediaId){
              ele2.sExpireDate = sDate;
            }
          });
        });

        this.$refs.tableObj.$forceUpdate();
        this.SearchMediaList=[];
        this.$refs.tableObj.clearSelect();
        this.viewSetDateVisible=false;
        this.onProductSearch();
      })
    },

    async onSync(){
      this.$confirm('是否确定同步，同步过程中会造成短暂的前端异常？ 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {    
              await Sync_UpdatePackageAndProductStatus({userId: getToken()}).then(()=>{
                this.$message({message: '同步完成',type: 'success'});
              });        
					})

      
        
    },

    async onSetStatus(){
      var mids="";
      this.selectedMediaList.forEach(ele => {
        mids=mids+','+ele.mediaId;
      });
      
      await SetMediaStatus({userId: getToken(),mids,statusName:this.setForm.statusName}).then(()=>{
        this.$message({message: '设置成功',type: 'success'});
        this.selectedMediaList.forEach(ele => {
          this.Media_LedList.forEach(ele2 => {
            if (ele.mediaId==ele2.mediaId){
              ele2.statusName = this.setForm.statusName;
            }
          });
        });

        this.$refs.tableObj.$forceUpdate();
        this.SearchMediaList=[];
        this.$refs.tableObj.clearSelect();
        this.viewSetStatusVisible=false;
        // this.onProductSearch();
      })

      // this.$confirm('确认切换资源可用状态, 是否继续?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     var mids="";
      //     this.selectedMediaList.forEach(ele => {
      //       mids=mids+','+ele.mediaId;
      //     });
      //     var sDate = new Date(this.setForm.expDate).toLocaleDateString();
      //     UpdateMedia_isDel({userId: getToken(),mediaIds:mids}).then(()=>{
      //       this.$message({message: '设置成功',type: 'success'});

      //       this.selectedMediaList.forEach(ele => {
      //         this.Media_LedList.forEach(ele2 => {
      //           if (ele.mediaId==ele2.mediaId){
      //             ele2.isDel = !ele2.isDel;
      //           }
      //         });
      //       });

      //       this.$refs.tableObj.$forceUpdate();
      //       this.SearchMediaList=[];
      //       this.$refs.tableObj.clearSelect();
      //       // this.viewSetDateVisible=false;
      //       this.onProductSearch();
      //     })
      //   }).catch(() => {
                 
      //   });      
    },

    open() {
      console.log("do open");
      this.$nextTick(() => {
        //  执行echarts方法
      });
    },

    handleSelectionChange(val){
      this.selectedMediaList = val;			
      // console.log(val);
    } ,
    
    onStationXmlChange(event) {
			// console.log('onStationXmlArrayChange', this.stationArray);
      var statStr ='';
      this.productSearchForm.stationArray.forEach(element => {
        statStr=statStr+element[2]+','
      })
      this.productSearchForm.stationStr=statStr;
					// this.$emit('change', this.stationXmlArray);
	},

    async onItemEditClick(item) {
			console.log('item',item);
			this.curItem = item;
      var imgArr = item.images?.split(';').filter(item=>!!item).map(fileName => {
      return {name: fileName, url: getFileUrl({name: fileName,subDir:'媒体资源照片'})};
      }) ?? []
      this.curItem.imgArr = imgArr,
      
			this.editDialogVisible = true;
	},
      
    async onItemNew(){
      this.curItem = {isDel:0,mediaId:'',typeId:1,imgArr:[]};
	    this.editDialogVisible = true;
    },

    async onSaveModify(){
      const {mediaId,positionId,stationId,typeId,sub1TypeId,sub2TypeId,mediaCode,address,areaPercent,adSize,adArea,mediaImg,imgArr,remark,sExpireDate,statusName} = this.curItem;
      var imgs='';
      
      if (imgArr?.length>0){
        imgArr.forEach(ele => {
          if (imgs==''){
            imgs = ele.name;
          }else{
            imgs = imgs +';'+ ele.name;
          }
        });
      }
      var exd = new Date(sExpireDate).toLocaleDateString();
      console.log('aa',exd,this.curItem);
      // return;
      var sId=0;
      if (mediaId==''){
        sId=stationId[2];
      } else{
        sId=stationId;
      }
      console.log('bb',this.curItem,stationId,sId);
      await UpdateMedia({
        userId:getToken(),
        mediaId,positionId,stationId:sId,typeId,sub1TypeId,sub2TypeId,mediaCode,address,areaPercent,adSize,adArea,mediaImg:imgs,remark,expireDate:exd,statusName
      }).then(res=>{
        this.editDialogVisible =false;
        this.onProductSearch();
      });
    },


    async onItemDelete(item) {
      // console.log(item);
      await dealDeleteOperation({deletePromise:delBrand({userId: getToken(), brandId: item.brandId,type:'1'})})
      await this.onProductSearch();
    },

    openStation(station){
      this.station=station;
      this.viewStationVisible=true;
    },

    async onProductSearch() {
      await this.$store.dispatch("operate/Media_LedList", {
        stationIds:this.productSearchForm.stationStr,
        statusName:this.productSearchForm.statusName,
        key: this.productSearchForm.key,
      }).then(res=>{
          this.SearchMediaList=[];
          this.$refs.tableObj.clearSelect();
      });
    },

    getRowKey(row) {
                return row.mediaId;
            },

  },
};
</script>

<style scoped>
.error{
    color: red;
}
.ok{
    color: green;
}

.youhua{  color:gray;}
.xinzeng{  color:red;}
.daoqi{color:gray;}
.zhengchang{color:green}
.yichang{color:yellow}

.curContainer {
    /* padding: 30px; */
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}

</style>